import React, {createContext, Dispatch, lazy, Suspense, useCallback, useContext, useEffect, useState} from "react";
import {iStoreAction} from "../reducer";
import {AppDispatchContext, AppStateContext} from "../App";
import useIsMounted from "ismounted";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {Alert, AlertColor, Snackbar} from "@mui/material";
import {CustomLoader} from "../Spinner/CustomLoader";
import BaseAPIs from "../apis/base.apis";
import {QueryParser1} from "../components/QueryParser";

const Covered = lazy(() => import('./Covered'));
const RestrictedItems = lazy(() => import('./RestrictedItems'));
const TermsConditions = lazy(() => import('./TermsConditions'));
const HomePage = lazy(() => import('./HomePage/HomePage'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const AffiliateDashboard = lazy(() => import('./Affiliate/Affiliate.Dashboard'));
const AboutUs = lazy(() => import('./AboutUs'));
const Login = lazy(() => import('./Login/Login'));
const Register = lazy(() => import('./Register/Register'));


export const SnackbarContext = createContext<{
    showSuccessSnackbarMessage: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void,
    showErrorSnackbarMessage: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void
}>({
    showErrorSnackbarMessage: args => {
    },
    showSuccessSnackbarMessage: args => {
    },
});
export default function AppRouter() {

    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);

    const [loading, setLoading] = useState(true);

    const {loggedInUser} = useContext(AppStateContext);
    const isMounted = useIsMounted();
    const onFetchUser = useCallback(() => {
        setLoading(true);
        new BaseAPIs().get("/_api/v2/get-user")
            .then((response) => {
                if (isMounted.current) {
                    if (BaseAPIs.hasError(response)) {
                        setLoading(false);
                    } else {
                        if(response.user){
                            dispatch({
                                type: "set_logged_in_user",
                                loggedInUser: response.user,
                            })
                        }

                        setLoading(false);

                    }
                }
            });
    }, [isMounted]);

    const onFetchRates = useCallback(() => {
        new BaseAPIs().get("/_api/v2/user/referrals/rates")
            .then((response) => {
                if (isMounted.current) {
                    if (BaseAPIs.hasError(response)) {
                        setLoading(false);
                    } else {
                        dispatch({
                            type: "rates",
                            rates:response
                        })

                    }
                }
            });

    }, [isMounted]);


    useEffect(() => {
        onFetchUser();
    }, [])
    useEffect(() => {
        if(loggedInUser){
            onFetchRates();
        }

    }, [loggedInUser])

    useEffect(() => {
        const handleInvalidToken = (e: any) => {
            if (e.key === 'loggedInUser_Directship' && e.oldValue && !e.newValue) {
                window.location.reload();
            }
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    const [snackBarState, setSnackBarState] = useState<{
        show: boolean,
        severity: AlertColor,
        message: string
    }>({
        show: false,
        severity: "success",
        message: "Hi"
    });
    if (loading) {
        return <CustomLoader/>;
    }
    return <>
        <SnackbarContext.Provider value={{
            showErrorSnackbarMessage: (args => {
                setSnackBarState(prevState => {
                    return {...prevState, show: true, severity: "warning", ...args}
                });
            }),
            showSuccessSnackbarMessage: (args => {
                setSnackBarState(prevState => {
                    return {...prevState, show: true, severity: "success", ...args}
                });
            }),
        }}>
            <Suspense fallback={<CustomLoader/>}>
                <Routes>
                    <Route path="/login" element={<RequireAuth redirect="/" noAuth><Login/></RequireAuth>}/>
                    <Route path="/register" element={<RequireAuth redirect={"/"} noAuth><Register/></RequireAuth>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path={"/about-us"} element={<AboutUs/>}/>
                    <Route path="/terms-conditions" element={<TermsConditions/>}/>
                    <Route path="/restricted-items" element={<RestrictedItems/>}/>
                    <Route path="/covered" element={<Covered/>}/>
                    <Route path="/affiliate/*" element={<AffiliateDashboard/>}/>
                    <Route path="*" element={<RequireAuth redirect={"/login"}><HomePage/></RequireAuth>}/>
                </Routes>
            </Suspense>
        </SnackbarContext.Provider>
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={snackBarState.show} autoHideDuration={6000} onClose={() => {
            setSnackBarState(prevState => {
                return {...prevState, show: false}
            });
        }}>
            <Alert onClose={() => {
                setSnackBarState(prevState => {
                    return {...prevState, show: false}
                });
            }} severity={snackBarState.severity} sx={{width: '100%'}}>
                {snackBarState.message}
            </Alert>
        </Snackbar>
    </>
}
export const RequireAuth = ({

                                noAuth,
                                redirect,
                                children
                            }: {
    noAuth?: boolean,
    redirect?: string,
    children: any,

}) => {

    const {loggedInUser} = useContext(AppStateContext)
    console.log(loggedInUser)
    console.log(noAuth)
    const location = useLocation()
    if (noAuth) {
        if (!loggedInUser || !loggedInUser.email) {
            return children;
        }else {
            return <Navigate replace to={redirect || "/"}/>
        }
    }
    if (loggedInUser && loggedInUser.email) {
        return children;
    }else if(loggedInUser && !loggedInUser.email) {
        return <Navigate replace to={"/register"}/>
    }

    let query = QueryParser1(location)
    if (query && query.go) {
        return <Navigate replace to={"/" + query.go}/>
    }
    return <Navigate replace to={redirect || "/"}/>

}
