import React, {createContext, useReducer} from 'react';
import AppRouter from "./pages/AppRouter";
import {BrowserRouter} from "react-router-dom";
import {appReducer, getInitialStoreState} from "./reducer";
import {iStoreState} from "./types/types";
import {ThemeProvider} from "@mui/material";
import {DefaultTheme} from "./Theme";
import "./gobal-style.css"


export const AppStateContext = createContext<iStoreState>(getInitialStoreState());
export const AppDispatchContext = createContext<any>({});

function App() {

    const [state, dispatch] = useReducer(appReducer, getInitialStoreState());
    return <BrowserRouter>
        <ThemeProvider theme={DefaultTheme}>
            <AppDispatchContext.Provider value={dispatch}>
                <AppStateContext.Provider value={state}>
                    <AppRouter/>
                </AppStateContext.Provider>
            </AppDispatchContext.Provider>
        </ThemeProvider>
    </BrowserRouter>
}

export default App;
